<template>
    <nav id="nav" style="display: flex;" >
        <div class="n-head" style="transform: ttranslate(0px, -302px);">
            <div class="n-logo" id="n-logo" style=""></div>
        </div>
        <div class="n-figure" style="transform: translate(0px, -302px);">
            <div class="n-gallery" style="transform: translate(0px, 0px);">
                <div class="n-image" style="">
                    
                </div>
            </div>
        </div>
        <div class="n-menu" style="transform: translate(0px, -100vh);">
            <div class="n-menu-column" style="">
                <div class="n-menu-align">
                    <ul class="n-menu-main"  v-for="(itm,index) in data" :key="index">
                        <li style="opacity: 1;" v-for="(itm,index) in itm.child" :key="index">
                            <a aria-current="page" v-if="itm.link == 'myaccount' && !userInfo.id" @click="openSigninPop()">{{itm.title}}</a>
                            <a :href="'#/' + itm.link" aria-current="page" v-else>{{itm.title}}</a>
                            <!-- <a :href="'#/' + itm.link" aria-current="page" data-transition="navigation">{{itm.title}}</a> -->
                        </li>
                        <!-- <li style="opacity: 1;"><a href="/collection/" data-transition="navigation">Collection</a></li>
                        <li style="opacity: 1;"><a href="/lookbook/" data-transition="navigation">Lookbook</a></li>
                        <li style="opacity: 1;"><a href="/about/" data-transition="navigation">About</a></li>
                        <li style="opacity: 1;"><a href="/contact/" data-transition="navigation">Contact</a></li> -->
                    </ul>
                    <ul class="n-menu-sub">
                        <li style="opacity: 1;"><a href="#/content/privacy-policy" class="is-sub" data-transition="navigation">Privacy Policy</a></li>
                        <!-- <li style="opacity: 1;"><a target="_blank" rel="noopener" href="/" data-transition="navigation">Store Locator</a></li> -->
                        <li style="opacity: 1;"><a target="_blank" rel="noopener" v-if="!userInfo.id"  @click="openSigninPop()" data-transition="navigation">Login</a>
                        </li>
                        <li style="opacity: 1;"><a target="_blank" rel="noopener" v-if="userInfo.id"  @click="logout()" data-transition="navigation">Log Out</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="n-menu-column" style="">
                <div class="n-menu-align">
                    <ul class="n-menu-links">
                        <li style="opacity: 1;"><strong>Social</strong></li>
                        <li style="opacity: 1;"><a target="_blank" href="https://www.instagram.com/meethasyk/?hl=en" rel="noopener">Instagram</a></li>
                        <li style="opacity: 1;"><a target="_blank" href="https://www.facebook.com/meethasyk" rel="noopener">Facebook</a></li>
                    </ul>
                    <ul class="n-menu-links">
                        <li style="opacity: 1;"><a class="is-sub" href="#/content/terms-and-conditions" data-transition="navigation">Terms &amp; Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="n-background" style="opacity: 0;"></div> -->
    </nav>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            data: {},
            active: false,
            userInfo:{}
        }
    },
    created(){
        this.init()
    },
    mounted(){
         window.addEventListener('scroll', this.handleScroll);
    },
    watch: {
    "$route.params": {
      handler(newValue, preValue) {
        //   this.init()
          if(document.getElementById('nav')){
              document.getElementById('nav').classList.remove('openNavigation') 
          }
      },
      immediate: true
    }
  },
    methods:{
        handleScroll(){
        },
        init(){
            this.userInfo = this.$store.state.customer
            axios
            .post(
                this.$store.state.api + "navigation/_search",
                {
                from: 0,
                size: 5000,
                query: {
                bool: {
                  must: [
                    {
                      match_phrase: {
                        event: this.$store.state.event
                      }
                    },
                      {
                      match_phrase: {
                        heading: "header"
                      }
                    }
                  ]
                }
              }
            }
            ).then(response => {
                this.data = {}
                var res = response.data.hits.hits
                for(var ind in res){
                    this.data[ind] = res[ind]._source
                   
                }
            })
        },
        openSigninPop(){
            if(document.getElementById('signinContainer')){
                document.getElementById('signinContainer').classList.add('showSigninContainer'); 
            }
        },
        logout(){
            localStorage.removeItem('users')
            this.$router.push({path:'/page/home'})

            location.reload()
        }
    }
}
</script>

<style scoped>
.displayNone{
    transition: all ease .5s !important;
    opacity: 0;
}
#nav {
    top: -100%;
    z-index: 92;
}
.n-logo {
    top: 0;
    left: 0;
    width: 11vw;
    height: 10vw;
    z-index: 3;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    background-image: url('../assets/logo.png');
}
.n-menu-column {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 2.188vw 0;
    align-items: flex-end;
}
@media only screen and (max-width: 580px){
    .n-logo{
        width: 35vw;
        height: 30vw;
    }
    .openNavigation .n-menu{
        height: 100vh;
        overflow: scroll;
    }
    .n-menu-column:nth-child(1) {
        padding: 41.667vw 0 0 !important;
    }
}
</style>